import React, { useState, useMemo } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import ManageAccountGrid from '../../../managementAccount/manageAccountGrid/index';
import formatters from '../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Modal/dialogModal';
import NominalTransaction from '../NominalTransaction';
import { Grid } from '@material-ui/core';
import AccountCodesHistory from './AccountCodeHistory';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Account Codes Overview', active: true }
];

const basecolumns = [
    { field: 'ac', title: 'Account Code', width: 130 },
    { field: 'fb', title: 'Brought Forward', width: 130, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    { field: 'cp', title: 'Current Postings', width: 130, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    { field: 'cfb', title: 'Carry Forward', width: 130, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    { field: 'fp', title: 'Future Postings', width: 130, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    {field: 'noi', title: 'Items', width: 80, align: 'right', filterable: false},
    { field: 'egn', title: 'Expense Group', width: 230 },
    { field: 'en', title: 'Expense Category', width: 200 },
    { field: 'cn', title: 'Category', width: 230 }
    // { field: 'dn', title: 'Division', width: 120 },
    // { field: 'ccg', title: 'Cost Centre Group', width: 150 },
    // { field: 'cc', title: 'Cost Centre', width: 250 },
    // { field: 'branch', title: 'Branch', width: 150 },
    // { field: 'department', title: 'Department', width: 250 },
    // { field: 'model', title: 'Model', width: 250 }
];

const AccountCodesOverview = () => {
    const [state, setState] = useState({
        showLoader: true,
        selectedRowData: {}
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [AccountCode, setAccountCode] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
        setHistoryModalOpen(false);
        setAccountCode(null);
    };

    const handleOpenModal = async (fileName) => {
        setAccountCode(fileName);
        setModalOpen(true);
    };
    
    const handleHistoryModal = async (fileName) => {
        setAccountCode(fileName);
        setHistoryModalOpen(true);
    };

    const ItemColumnWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" justifyContent="flex-end">
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                    onClick={() => handleOpenModal(record.ac)}
                >
                    {formatters.ThousandSeparatorWithoutZero(value)}
                </div>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const AccCodesColumnWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                    onClick={() => handleHistoryModal(record.ac)}
                >
                   {value}
                </div>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...basecolumns];

        let colItems = col.find((m) => m.field === 'noi');

        if (colItems) {
            colItems.renderer = ItemColumnWrapper;
        }
        let colAccCode = col.find((m) => m.field === 'ac');
        
        if (colAccCode) {
            colAccCode.renderer = AccCodesColumnWrapper;
        }

        return col;
    }, []);

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <ManageAccountGrid
                columns={column}
                getUrl={`NominalCodes/MANominalAccountCodes_Overview`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                downloadBtnName={'Account Codes Overview'}
            />
            {modalOpen ? (
                <DialogComp title={`Transactions - ${AccountCode}`} onClose={() => handleCloseModal()} maxWidth="xl" fullWidth overflow>
                    <NominalTransaction AccountCode={AccountCode} />
                </DialogComp>
            ) : null}
            {historyModalOpen ? (
                <DialogComp title={`History - ${AccountCode}`} onClose={() => handleCloseModal()} maxWidth="xl" fullScreen overflow>
                    <AccountCodesHistory AccountCode={AccountCode} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default AccountCodesOverview;
