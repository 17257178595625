import React, { useState, useMemo, useEffect } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import ManageAccountGrid from '../../../managementAccount/manageAccountGrid/index';
import formatters from '../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Modal/dialogModal';
import NominalTransaction from '../NominalTransaction';
import { Grid, Typography } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { getMANominalAccountHistoryByAccount } from '../../../../Core/Service/ManageAccount/manageAccountService';

// const crumbs = [
//     { name: 'Home', path: '/' },
//     { name: 'Management Account', active: true },
//     { name: 'Nominal Account Codes', active: true }
// ];

const COLORS = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#C33C9A',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'rgba(0,0,0,0.5)', fontSize: 16, padding: 10, color: 'white' }}>
                <p style={{ color: 'white' }}>
                    <b>{`${label}`}</b>
                </p>
                {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                {payload[0].name}&nbsp;:&nbsp;&nbsp;<b>{formatters.CurrencyThousandSeparatorWithoutZero(payload[0].payload.Amount)}</b>
            </div>
        );
    }

    return null;
};

const CustomYTick = ({ x, y, payload, customFill }) => {
    return (
        <text x={x - 25} y={y} textAnchor="middle" fill={customFill} fontSize={10} fontWeight={700}>
            {`£${Math.round(payload.value).toLocaleString()}`}
        </text>
    );
};

const basecolumns = [
    {
        field: 'year',
        title: 'Year',
        width: 40
    },
    { field: 'period', title: 'Period', width: 40 },
    { field: 'broughtForwardBalance', title: 'Brought Forward', width: 80, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { field: 'currentPostings', title: 'Current Postings', width: 80, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { field: 'carryForwardBalance', title: 'Carry Forward', width: 80, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { field: 'futurePostings', title: 'Future Postings', width: 80, renderer: formatters.CurrencyThousandSeparator, align: 'right' }

    // { field: 'branch', title: 'Branch', width: 150 },
    // { field: 'department', title: 'Department', width: 250 },
    // { field: 'model', title: 'Model', width: 250 }
];

const AccountCodesHistory = (props) => {
    const [state, setState] = useState({
        showLoader: true,
        selectedRowData: {},
        rows: []
    });
    const [currentLineGraphData, setCurrentLineGraphData] = useState([]);
    const [carryLineGraphData, setCarryLineGraphData] = useState([]);

    const column = useMemo(() => {
        let col = [...basecolumns];

        return col;
    }, []);

    useEffect(async () => {
        let res = await getMANominalAccountHistoryByAccount(props.AccountCode);
        setState((st) => ({
            ...st,
            rows: res.data.list
        }));

        let currentGraphData = res.data.list.slice(0, 12).map((item) => {
            return { name: `${item.period}-${item.year} `, Amount: item.currentPostings };
        });
        let carryGraphData = [...res.data.list].slice(0, 12).map((item) => {
            return { name: `${item.period}-${item.year} `, Amount: item.carryForwardBalance };
        });

        setCurrentLineGraphData(currentGraphData);
        setCarryLineGraphData(carryGraphData);
    }, []);

    return (
        <div className="report-screen-container">
            {/* <BreadCrumbs crumbs={crumbs} /> */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                    <ManageAccountGrid
                        columns={column}
                        localRowsData={state.rows}
                        // getUrl={`NominalCodes/MANominalAccountHistoryByAccount?AccountCode=${props.AccountCode || ''}`}
                        // dataConverter={(res) => ({
                        //     records: state.rows,
                        //     total: state.rows.length
                        // })}
                        minWidth={null}
                        isReload={state.isReload}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: 10,
                                            marginBottom: 20,
                                            fontWeight: 600,
                                            fontSize: 24
                                        }}
                                    >
                                        NL Account Balances
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ height: '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            width={500}
                                            height={300}
                                            data={carryLineGraphData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="name"
                                                textAnchor="end"
                                                sclaeToFit="true"
                                                verticalAnchor="start"
                                                allowDataOverflow={false}
                                                interval={0}
                                                // dy={-5}
                                                angle={'-20'}
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 700
                                                }}
                                            />
                                            <YAxis tick={<CustomYTick customFill={'#8884d8'} />} />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Line type="monotone" dataKey="Amount" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: 10,
                                            marginBottom: 20,
                                            fontWeight: 600,
                                            fontSize: 24
                                        }}
                                    >
                                        NL Account Movements
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ height: '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            width={500}
                                            height={300}
                                            data={currentLineGraphData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip content={<CustomTooltip />} />
                                            <XAxis
                                                dataKey="name"
                                                textAnchor="end"
                                                sclaeToFit="true"
                                                verticalAnchor="start"
                                                allowDataOverflow={false}
                                                interval={0}
                                                // dy={-5}
                                                angle={'-20'}
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 700
                                                }}
                                            />
                                            <YAxis tick={<CustomYTick customFill={'#82ca9d'} />} />
                                            {/* <Tooltip /> */}
                                            <Line type="monotone" dataKey="Amount" stroke="#82ca9d" strokeWidth={2} activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountCodesHistory;
