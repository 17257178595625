import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useEffect, useContext, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import { getWorkflowDashBoard, getWorkflowDashBoardInternalScreenDropdown } from '../../../Core/Service/workflowJobs-service';
import { InputLabel, Tooltip, CircularProgress, ButtonGroup, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import '../../../Core/Dashbord/mainDashboard.scss';
import { useHistory } from 'react-router-dom';

import '../../customer/dashboard.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const color = {};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0,
        border: '2px solid white ',
        Opacity: 0.9,
        minWidth: 120,
        minHeight: 148,
        padding: '0px 0px',
        background: 'white',
        borderRadius: 10
    }
}));

export default function WorkflowDash(props) {
    const history = useHistory();
    const classes = useStyles();
    var urlParams = new URLSearchParams(window.location.search);
    var workFlowIdParam = urlParams.get('workFlowId');
    var ProcessID = urlParams.get('ProcessID');
    console.log(ProcessID, 'workflowdash');
    var workFlowNameParam = urlParams.get('workFlowName');
    const [state, setState] = useState({
        reaturnReasonList: [],
        returnReasons: [],
        showLoader: true,
        returnReasonLoader: true,
        workFlowId: workFlowIdParam,
        workFlowDataName: workFlowNameParam,
        branchesList: [],
        workflowsList: [],
        branchId: '',
        sortedReaturnReasonList: [],
        branchName: ''
    });
    const { showToast, tempMenus } = useContext(AppContext);

    useEffect(async () => {
        setState((st) => ({ ...st, showLoader: true }));
        let res = await getWorkflowDashBoardInternalScreenDropdown();
        if (res.success) {
            let isworkFlowAvailable = res.data.workflows.findIndex((p) => p.id == workFlowIdParam);

            if (isworkFlowAvailable <= -1) {
                res.data.workflows.push({
                    id: workFlowIdParam,
                    name: workFlowNameParam,
                    total: 0
                });
            }
            setState((st) => ({ ...st, branchesList: res.data.branches, workflowsList: res.data.workflows, showLoader: false }));
        }
        if (!res.success) {
            showToast(res.message);
        }
    }, []);

    useEffect(async () => {
        setState((st) => ({ ...st, returnReasonLoader: true }));
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: '' }));
        let res = await getWorkflowDashBoard(state.workFlowId);
        if (res.success) {
            let TotalObj = 0;
            let tempReaturnReasonList = res.data.details || [];
            tempReaturnReasonList.forEach((q) => {
                TotalObj += q.total;
            });
            tempReaturnReasonList.push({
                bid: null,
                branch: 'Total',
                colourCode: '#ff0000',
                returnReason: 'Total',
                returnReasonID: null,
                textColourCode: '#ffffff',
                total: TotalObj
            });

            setState((st) => ({
                ...st,
                reaturnReasonList: tempReaturnReasonList,
                sortedReaturnReasonList: sortReturnReasonByBranch(tempReaturnReasonList),
                returnReasonLoader: false
            }));
        }
        if (!res.success) {
            showToast(res.message);
        }
    }, [state.workFlowId]);

    useEffect(async () => {
        setState((st) => ({ ...st, sortedReaturnReasonList: sortReturnReasonByBranch(state.reaturnReasonList) }));
    }, [state.branchId]);

    const sortReturnReasonByBranch = (list) => {
        let sortedReaturnReasons = [];
        if (state.branchId) {
            return (sortedReaturnReasons = list.filter((q) => q.bid == state.branchId));
        } else {
            list.forEach((q) => {
                let isReturnRes = sortedReaturnReasons.findIndex((p) => p.returnReasonID == q.returnReasonID);
                if (isReturnRes > -1) {
                    sortedReaturnReasons[isReturnRes].total += q.total;
                } else {
                    sortedReaturnReasons.push({ ...q });
                }
            });

            return sortedReaturnReasons;
        }
    };

    const handleWorkFlow = (workFlowData) => {
        setState((st) => ({ ...st, workFlowId: workFlowData.id }));
    };

    const handleChangeByTime = (e) => {
        let valueByTime = e.target.value;

        setState((st) => ({ ...st, branchId: valueByTime, branchName: state.branchesList.find((k) => k.bid == e.target.value)?.branch || '' }));
    };

    const handleBar = (e) => {
        setState((st) => ({ ...st, workFlowId: e.target.value }));
    };

    const toRedirectToGrid = (data) => {
        history.push({
            pathname: `/dashgrid`,
            query: {
                workFlowId: state.workFlowId,
                returnReason: `${data.returnReasonID == 0 ? '' : data.returnReason}`,
                returnReasonID: data.returnReasonID,
                ProcessID: ProcessID
            }
        });
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: state.branchName || '' }));
    };

    return (
        <div className="customer-dashboard">
            <Container className="cardGrid" maxWidth={false} style={{ paddingTop: '20px' }}>
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justify="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Card className={classes.root}>
                            <Hidden only={['xs', 'sm', 'md']}>
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={12} sm={10}>
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'left',
                                                color: '#f50057',
                                                fontWeight: 600,
                                                margin: '10px 20px'
                                            }}
                                        >
                                            <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                                                {state.workflowsList.map((n, i) => {
                                                    return (
                                                        <Button
                                                            key={i}
                                                            variant={n.id == state.workFlowId ? 'contained' : 'outlined'}
                                                            onClick={() => handleWorkFlow(n)}
                                                            style={{ fontSize: 10 }}
                                                        >
                                                            {n.name}&nbsp;-&nbsp;{n.total}
                                                        </Button>
                                                    );
                                                })}
                                            </ButtonGroup>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div style={{ float: 'right', marginRight: 40, marginTop: '10px' }}>
                                            <select onChange={handleChangeByTime} className="selectclass" value={state.branchId}>
                                                <option value={''}>All Branches</option>
                                                {state.branchesList.map((btn, i) => {
                                                    return (
                                                        <option key={i} value={btn.bid}>
                                                            {btn.branch}{' '}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden only={['lg', 'xl']}>
                                <Grid container justifyContent="space-between" spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <div
                                            style={{
                                                margin: '0px 20px',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <span className="lableFont">Workflow</span>
                                            <select
                                                color="secondary"
                                                onChange={handleBar}
                                                aria-label="outlined primary button group"
                                                size="small"
                                                className="ButtonDropDown"
                                                value={state.workFlowId}
                                            >
                                                {state.workflowsList.map((n, i) => {
                                                    return (
                                                        <option key={i} value={n.id}>
                                                            {n.name}&nbsp;-&nbsp;{n.total}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div style={{ margin: '0px 20px', textAlign: 'left' }}>
                                            <span className="lableFont">Branch</span>
                                            <select onChange={handleChangeByTime} className="selectclass" value={state.branchId}>
                                                <option value={''}>All Branches</option>
                                                {state.branchesList.map((btn, i) => {
                                                    return (
                                                        <option key={i} value={btn.bid}>
                                                            {btn.branch}{' '}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <div style={{ padding: '0px 20px' }}>
                                <hr
                                    style={{
                                        flexShrink: 0,
                                        borderWidth: '0px 0px thin',
                                        borderStyle: 'solid',
                                        borderColor: 'rgba(58, 53, 65, 0.12)',
                                        margin: '14px 0px'
                                    }}
                                />
                            </div>
                            {state.returnReasonLoader ? (
                                <Grid item sm={12} container alignItems="center" justify="center">
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <Grid container spacing={0} style={{ padding: '0px 20px' }} justifyContent="flex-start">
                                    {state.sortedReaturnReasonList.length > 0 ? (
                                        <>
                                            {state.sortedReaturnReasonList.map((g) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={5}
                                                        lg={3}
                                                        xl={2}
                                                        style={{
                                                            padding: '15px 0px',
                                                            borderWidth: '0px 0px thin',
                                                            borderStyle: 'solid',
                                                            borderColor: 'rgba(58, 53, 65, 0.12)'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                borderLeft: `5px solid ${g.colourCode}`,
                                                                padding: '5px 0px 0px 20px',
                                                                width: '100%',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <div
                                                                style={{ paddingRight: 20, paddingBottom: 5, textAlign: 'left' }}
                                                                onClick={() => toRedirectToGrid(g)}
                                                            >
                                                                <span className="lableFont">{g.returnReason}</span>
                                                                <div className="valueFont">{g.total}</div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <Grid item xs style={{ marginTop: '20px' }}>
                                            No record Found
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Card>
                    </>
                )}
            </Container>
        </div>
    );
}
