import React, { useContext, useState, useMemo, useEffect } from 'react';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import ManageAccountGrid from '../../../managementAccount/manageAccountGrid/index';
import formatters from '../../../../Core/Grid/inputFormatter';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Modal/dialogModal';
import { Grid, InputLabel, FormHelperText } from '@material-ui/core';
import AccountCodesHistory from '../AccountCodesOverview/AccountCodeHistory';
import { SecondaryButton, SingelSelect } from '../../../../Core/FormInput';
import { SimpleField } from '../../../../Core/FormInput/inputfields';
import AppContext from '../../../../App/AppContext';
import { getPassedPeriod } from '../../../../Core/Service/period-service';
import NominalTransaction from '../NominalTransaction';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Management Account', active: true },
    { name: 'Nominal Account History', active: true }
];

const basecolumns = [
    { field: 'ac', title: 'Account Code', width: 100 },
    { field: 'fb', title: 'Brought Forward', width: 100, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    { field: 'cp', title: 'Current Postings', width: 100, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    { field: 'cfb', title: 'Carry Forward', width: 100, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
    { field: 'ec', title: 'Expense Code', width: 100 },
    { field: 'eg', title: 'Expense Group', width: 150 },
    { field: 'cn', title: 'Category', width: 170 }
    // { field: 'fp', title: 'Future Postings', width: 100, renderer: formatters.CurrencyThousandSeparator, align: 'right', filterable: false },
];

const NominalAccountHistory = () => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        showLoader: true,
        period: null,
        year: null,
        ac: '',
        errors: {},
        yearList: [],
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [AccountCode, setAccountCode] = useState(null);

    const handleSingleSelect = (name) => (ev, val) => {
        setState((st) => ({
            ...st,
            [name]: val
        }));
    };

    const handleAcSelect = (ev) => {
        const { name, value } = ev.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setHistoryModalOpen(false);
        setAccountCode(null);
    };

    const period = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' }
    ];

    const handleHistoryModal = async (fileName) => {
        setAccountCode(fileName);
        setHistoryModalOpen(true);
    };

    const handleCpModal = async (fileName) => {
        setAccountCode(fileName);
        setModalOpen(true);
    };

    const AccCodesColumnWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                    onClick={() => handleHistoryModal(record.ac)}
                >
                    {value}
                </div>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const CpColumnWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" justifyContent="flex-end">
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                    onClick={() => handleCpModal(record.ac)}
                >
                    {value}
                </div>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...basecolumns];
        let colAccCode = col.find((m) => m.field === 'ac');
        if (colAccCode) {
            colAccCode.renderer = AccCodesColumnWrapper;
        }
        let colCp = col.find((m) => m.field === 'cp');
        if (colCp) {
            colCp.renderer = CpColumnWrapper;
        }
        return col;
    }, []);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await getPassedPeriod();
                if (response.success) {
                    const { year, period } = response.data[0];
                    const startYear = year - 7;
                    const dynamicYears = Array.from(
                        { length: year - startYear + 1 },
                        (_, index) => ({
                            id: startYear + index,
                            name: (startYear + index).toString()
                        })
                    );
    
                    setState((st) => ({
                        ...st,
                        period: { id: period, name: period.toString() },
                        year: { id: year, name: year.toString() }, 
                        yearList: dynamicYears,
                        url: `NominalAccountHistory/MANominalAccountCodes_History?period=${period}&year=${year}`
                    }));
                }
            } catch (error) {
                showToast('Failed to fetch initial data', 'error');
            }
        };
        fetchInitialData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (!state.period) errors['period'] = "Period can't be empty";
        if (!state.year) errors['year'] = "Year can't be empty";
        
        if (Object.keys(errors).length > 0) {
            setState((st) => ({ ...st, errors }));
            return;
        }
    
        const periodValue = state.period?.id || state.period;
        const yearValue = state.year?.id || state.year;
        const { ac } = state;
    
        const url = `NominalAccountHistory/MANominalAccountCodes_History?ac=${ac || ''}&period=${periodValue}&year=${yearValue}`;
        setState((st) => ({ ...st, url, errors: {} }));
    };

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={1} style={{ marginTop: 3 }}>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                    <InputLabel className="mandatoryfields" shrink style={{ marginBottom: -7 }}>
                        Period*
                    </InputLabel>
                    <SingelSelect
                        options={period}
                        value={state.period}
                        onChange={handleSingleSelect('period')}
                    />
                    <FormHelperText className="mandatoryfields">{state.errors?.period} </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                    <InputLabel className="mandatoryfields" shrink style={{ marginBottom: -7 }}>
                        Year*
                    </InputLabel>
                    <SingelSelect
                        options={state.yearList}
                        value={state.year}
                        onChange={handleSingleSelect('year')}
                    />
                    <FormHelperText className="mandatoryfields">{state.errors?.year} </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <InputLabel shrink style={{ marginBottom: -7 }}>
                        Account Code
                    </InputLabel>
                    <SimpleField name="ac" onChange={handleAcSelect} value={state.ac} />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={{ marginTop: 7.5 }}>
                    <SecondaryButton
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        className="Submitbtn"
                        onClick={handleSubmit}
                    >
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
            <ManageAccountGrid
                columns={column}
                getUrl={state.url}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                downloadBtnName={'NominalAccountHistory'}
            />
            {historyModalOpen ? (
                <DialogComp title={`History - ${AccountCode}`} onClose={() => handleCloseModal()} maxWidth="xl" fullScreen overflow>
                    <AccountCodesHistory AccountCode={AccountCode} />
                </DialogComp>
            ) : null}
            {modalOpen ? (
                <DialogComp title={`Current Postings`} onClose={() => handleCloseModal()} maxWidth="xl" fullWidth overflow>
                    <NominalTransaction AccountCode={AccountCode} Period={state.period.name} Year={state.year.name} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default NominalAccountHistory;