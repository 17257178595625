import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, InputLabel } from '@material-ui/core';
import { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        border: 'none',
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    // root: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover
    //     }
    // }
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

export default function AvgTimeTable(props) {
    const classes = useStyles();
    let history = useHistory();

    const { tableList, tableBranchNames, start, end } = props;

    const [state, setState] = useState({
        selectedBranch: tableBranchNames[0],
        tableData: tableList,
        uniqueDates: [],
        CombinedList: []
    });

    useEffect(() => {
        setState((st) => ({
            ...st,
            selectedBranch: tableBranchNames[0]
        }));
    }, [props.tableBranchNames]);

    const handleDropDown = (e) => {
        setState((st) => ({
            ...st,
            selectedBranch: e.target.value
        }));
    };

    useEffect(() => {
        if (state.selectedBranch) {
            const filterArray = tableList.filter((k) => k.brShortName === state.selectedBranch);

            let CombinedList = [];
            let UniqueDateList = [];

            filterArray.forEach((p) => {
                UniqueDateList.push({ monthYear: p.monthYear });
                CombinedList.findIndex((q) => q.name === p.name) > -1
                    ? CombinedList[CombinedList.findIndex((q) => q.name === p.name)].data.push({ ...p })
                    : CombinedList.push({ name: p.name, id: p.wId, data: [{ ...p }] });
            });

            let uniqueDates = [...new Set(UniqueDateList.map((n) => n.monthYear))];
            // uniqueDates.sort((a, b) => {
            //     const [aMonth, aYear] = a.split('-');
            //     const [bMonth, bYear] = b.split('-');
            //     return aYear - bYear || months.indexOf(aMonth) - months.indexOf(bMonth);
            // });

            setState((st) => ({
                ...st,
                uniqueDates: [...uniqueDates],
                CombinedList: [...CombinedList]
            }));
        }
    }, [state.selectedBranch, tableList, start, end]);

    const handeleDataClick = (m) => {
        const [aMonth, aYear] = m.monthYear.split('-');
        console.log('aYear = ', aYear, 'aMonth =', aMonth);
        const startDate = moment(`${aYear}-${aMonth}`, 'YYYY-MMMM').startOf('month').format('YYYY-MM-DD');
        const endDate = moment(`${aYear}-${aMonth}`, 'YYYY-MMMM').endOf('month').format('YYYY-MM-DD');
        localStorage.setItem('currentWorkflowId', m.wId);
        history.push(`/dashgrid?startDate=${startDate}&endDate=${endDate}&fromReports=Yes&BranchIds=${m.brId}&ProcessID=1`);
    };
    return (
        <>
            <Grid container>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                    <InputLabel>Select Branch </InputLabel>
                    <select
                        style={{
                            minWidth: 40,
                            width: '100%',
                            padding: '10px 20px',
                            marginTop: 10
                        }}
                        onChange={handleDropDown}
                        value={state.selectedBranch}
                    >
                        {tableBranchNames.map((n, i) => {
                            return (
                                <option
                                    key={i}
                                    value={n}
                                    style={{
                                        padding: 30
                                    }}
                                >
                                    {n}
                                </option>
                            );
                        })}
                    </select>
                </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                <Table stickyHeader className={`${classes.table} custom-scroll`} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            {state.uniqueDates.map((date) => {
                                return <StyledTableCell align="right">{date}</StyledTableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.CombinedList.length > 0 ? (
                            state.CombinedList.map((row, i) => (
                                <StyledTableRow key={i}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    {row.data.map((k, i) => {
                                        let find = state.uniqueDates.findIndex((n) => n === k.monthYear);
                                        if (find > -1) {
                                            return (
                                                <StyledTableCell key={i} align="right" onClick={() => handeleDataClick(k)} style={{ cursor: 'pointer' }}>
                                                    <span title={`${k.days || '-'} day${k.days !== 1 ? 's' : ''}`}>{k.days || '-'}</span>
                                                    {' / '}
                                                    <span title={`${k.hr || '-'} hour${k.hr !== 1 ? 's' : ''}`}>{k.hr || '-'}</span>
                                                    {' / '}
                                                    <span title={`${k.min || '-'} minute${k.min !== 1 ? 's' : ''}`}>{k.min || '-'}</span>
                                                </StyledTableCell>
                                            );
                                        } else {
                                            return <StyledTableCell align="right">-</StyledTableCell>;
                                        }
                                    })}
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={5} align="center">
                                    NO Record Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
