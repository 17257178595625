import React, { useEffect, useState } from 'react';
import { GetInternalWorkflowsAvgTimesDashboard } from '../../../../Core/Service/dashbordService';
import { DatePicker, MultipleSelectWithMoreOp, Multiselect } from '../../../../Core/FormInput';
import { Card, CircularProgress, FormLabel, Grid, InputLabel, makeStyles, withStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import moment from 'moment';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';
import AvgTimeTable from './avgTimeTable';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import CircularIndeterminate from '../../ReportsScreens/ReportsScreens/BudgetCore/loader';
import { GetBranchDeportmentScreenDropdowns } from '../../../../Core/Service/reportService';
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        border: 'none',
        fontSize: 14,
        padding: 9
    }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3,
        cursor: 'pointer'
    },
    cardworkflow: {
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: '5px 20px'
    },
    table: {
        minWidth: 700
    }
}));

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Reports', active: true },
    { name: 'Workflows Average Times', path: '/', active: true }
];
const colors = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed', '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0', '#8AE82D'];
const WorkflowAvgTimeDash = () => {
    let WorkFlowBtnElementscrollWidth = document.getElementById('WorkFlow-Btn');
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const classes = useStyles();
    const [WindowWidths] = useWindowSize();

    function scrollbarVisible(element) {
        return element?.scrollWidth > element?.clientWidth;
    }
    let history = useHistory();

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        workflowTopList: [],
        isShowScrollArrow: true,
        branchCard: [],
        branchList: [],
        tableList: [],
        uniqueBranchName: [],
        tableData: [],
        uniqueWorkflow: [],
        showLoader: true,
        groupIds: []
    });

    const [temp, tempOption] = useState({
        groupVal: []
    });

    useEffect(() => {
        setState((st) => ({
            ...st,
            isShowScrollArrow: scrollbarVisible(WorkFlowBtnElementscrollWidth)
        }));
    }, [WindowWidths, WorkFlowBtnElementscrollWidth?.scrollWidth]);

    useEffect(async () => {
        let res = await GetBranchDeportmentScreenDropdowns();
        if (res.success) {
            setState((st) => ({
                ...st,
                branchList: res.data?.branchList || []
            }));
        }
    }, []);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));

        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };
    const scrollNext = (id) => {
        document.getElementById('WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
    };

    const scrollPrev = (id) => {
        document.getElementById('WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
    };
    const GetWorkflowsAvgTimes = async () => {
        if ((state.startDate, state.endDate)) {
            // setState((st) => ({ ...st, showLoader: true }));
            let res = await GetInternalWorkflowsAvgTimesDashboard(state.startDate, state.endDate, state.groupIds);
            if (res.success) {
                let allData = [...res.data?.allData] || [];
                let allTableBranches = allData.map((n) => n.brShortName);
                let uniqueBranchName = [...new Set(allTableBranches)];
                let branchHistory = [...res.data?.branchHistory] || [];
                let workflowHistory = [...res.data?.workflowHistory] || [];

                if (branchHistory.length > 0) {
                    let BrGraphData = [];
                    [...branchHistory].forEach((q) => {
                        let isIDExist = BrGraphData.findIndex((t) => t.name == q.name);
                        if (isIDExist > -1) {
                            BrGraphData[isIDExist][q.brShortName] = q.hr;
                        } else {
                            BrGraphData.push({
                                name: q.name,
                                [q.brShortName]: q.hr
                            });
                        }
                    });

                    const tableData = {};
                    for (const item of [...res.data?.branchHistory]) {
                        if (!tableData[item.name]) {
                            tableData[item.name] = { id: item.wId };
                        }
                        tableData[item.name][`${item.brShortName}id`] = item.brId;
                        tableData[item.name][item.brShortName] = (tableData[item.name][item.brShortName] || 0) + item.hr;
                    }
                    const uniqueWorkflow = Object.keys(tableData);

                    console.log(tableData, 'tableData');
                    setState((st) => ({
                        ...st,
                        branchCard: branchHistory || [],
                        tableData: tableData || [],
                        uniqueWorkflow: uniqueWorkflow || [],
                        BrGraphData: BrGraphData || [],
                        uniqueBranchName: uniqueBranchName || []
                    }));
                }
                setState((st) => ({
                    ...st,
                    workflowTopList: workflowHistory || [],
                    tableList: allData || [],
                    showLoader: false
                }));
            }
        }
    };

    useEffect(() => {
        GetWorkflowsAvgTimes();
    }, [state.startDate, state.endDate, state.groupIds]);

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    const handelCardClick = (wID) => {
        localStorage.setItem('currentWorkflowId', wID);
        let brIds = `${state.groupIds.length > 0 ? `&BranchIds=${state.groupIds}` : ''}`;
        history.push(`/dashgrid?startDate=${state.startDate}&endDate=${state.endDate}&fromReports=Yes${brIds}&ProcessID=1`);
    };

    const handelBranchClick = (wr, br) => {
        // localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: br }));
        localStorage.setItem('currentWorkflowId', state.tableData[wr]['id']);
        history.push(`/dashgrid?startDate=${state.startDate}&endDate=${state.endDate}&fromReports=Yes&BranchIds=${state.groupIds}&ProcessID=1`);
    };
    return (
        <div className="salesBudgetScreen custom-scroll" style={{ height: 'calc(100vh - 105px)', background: '#f5f5f5', overflow: 'auto' }}>
            {state.showLoader ? (
                <CircularIndeterminate height={`760px`} />
            ) : (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ marginTop: -8 }}>
                            <BreadCrumbs crumbs={crumbs} />
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                Start Date
                            </FormLabel>
                            <DatePicker
                                placeholder="Start Date"
                                name="startDate"
                                InputProps={{ inputProps: { max: `${state.endDate}` } }}
                                fullWidth
                                value={state.startDate}
                                onChange={handleinput}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                            <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                                End Date
                            </FormLabel>
                            <DatePicker
                                placeholder="End Date"
                                name="endDate"
                                InputProps={{ inputProps: { min: `${state.startDate}` } }}
                                fullWidth
                                value={state.endDate}
                                onChange={handleinput}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={30}>
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                                    Branch
                                </FormLabel>
                                <MultipleSelectWithMoreOp options={state.branchList || []} onChange={handleChanges} value={temp.groupVal} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ overflow: 'hidden', display: 'flex', width: '100%' }}>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                                        <ChevronLeftIcon />
                                    </span>
                                )}
                                <div style={{ overflow: 'auto', display: 'flex', width: '100%' }} className={`btn-container`} id="WorkFlow-Btn">
                                    {state.workflowTopList.map((m, i) => {
                                        return (
                                            <div item style={{ padding: '0px 5px 0px 0px', margin: `${i === 0 ? '0px 0px' : '0px 3px'}` }}>
                                                <Card
                                                    className={classes.root}
                                                    key={i}
                                                    style={{
                                                        background: '#ffffff',
                                                        padding: 0
                                                    }}
                                                    onClick={() => {
                                                        handelCardClick(m.wId);
                                                    }}
                                                >
                                                    <div className={classes.cardworkflow}>
                                                        <div style={{ fontSize: 20, marginBottom: 6, fontWeight: 600 }}> {m?.name}</div>
                                                        <InputLabel shrink>Days</InputLabel>
                                                        <div style={{ fontSize: 14, fontWeight: 600, textAlign: 'right' }}>{m?.days}</div>
                                                        <InputLabel shrink>Hours</InputLabel>
                                                        <div style={{ fontSize: 14, fontWeight: 600, textAlign: 'right' }}> {m?.hr}</div>
                                                        <InputLabel shrink>Minutes</InputLabel>
                                                        <div style={{ fontSize: 14, fontWeight: 600, textAlign: 'right' }}> {m?.min}</div>
                                                    </div>
                                                </Card>
                                            </div>
                                        );
                                    })}
                                </div>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                                        <ChevronRightIcon />
                                    </span>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} style={{ height: 250 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={state.BrGraphData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {state.uniqueBranchName.map((br, i) => {
                                        return <Line type="monotone" dataKey={br} stroke={colors[i]} activeDot={{ r: 8 }} strokeWidth={2} />;
                                    })}
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12}>
                            {state.uniqueBranchName.length > 0 && state.uniqueWorkflow.length > 0 && (
                                <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                                    <Table stickyHeader className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                {state.uniqueWorkflow.map((workflow) => {
                                                    return <StyledTableCell align="right">{workflow}</StyledTableCell>;
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.uniqueBranchName.length > 0 ? (
                                                state.uniqueBranchName.map((br, i) => (
                                                    <TableRow key={i}>
                                                        <StyledTableCell component="th" scope="br" style={{ backgroundColor: 'balck' }}>
                                                            {br}
                                                        </StyledTableCell>
                                                        {state?.uniqueWorkflow.length > 0 &&
                                                            state?.uniqueWorkflow.map((wr) => {
                                                                return (
                                                                    <StyledTableCell
                                                                        key={wr}
                                                                        align="right"
                                                                        onClick={() => handelBranchClick(wr, br)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <span
                                                                            title={`${state.tableData[wr][br] || '-'} Hour${
                                                                                state.tableData[wr][br] !== 1 ? 's' : ''
                                                                            }`}
                                                                        >
                                                                            {state.tableData[wr][br] || 0}
                                                                        </span>
                                                                    </StyledTableCell>
                                                                );
                                                            })}
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <StyledTableCell colSpan={5} align="center">
                                                        NO Record Found
                                                    </StyledTableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <AvgTimeTable tableList={state.tableList} tableBranchNames={state.uniqueBranchName} start={state.startDate} end={state.endDate} />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};
export default WorkflowAvgTimeDash;
