import React, { useEffect, useState, useMemo, useContext } from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FormLabel, IconButton, Tooltip, Typography } from '@material-ui/core';
import { BasicButtonGroup } from '../../../Core/ButtonGroup/index.jsx';
import { getWorkflows, getWorkflowsByProcessID } from '../../../Core/Service/workflow-service.js';
import AppContext from '../../../App/AppContext.js';

import moment from 'moment';
import './dashboard.scss';

import { makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution/index.js';

import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
import GijgoGrid from './index.jsx';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComp from '../../../Core/Modal/dialogModal.js';
import GridFilterModal from './FilterModal.jsx';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service.js';
import AssignTech from '../assign-technician/assignTechnModal.jsx';
import { Customi18 } from '../../../Core/Controls/i18react/customei18.js';
import _ from 'lodash';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LogHistory from '../Communicator/OpenActionLogs/logHistory.jsx';
import { AppStorage } from '../../../Core/Service/storage-service.js';
import JobPriorityModal from './jobPriorityModal.jsx';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ReportIcon from '@material-ui/icons/Report';
import CustomerRating from './customerRating.jsx';
import StarIcon from '@material-ui/icons/Star';
import MotHistoryScreen from '../ReportsScreens/MotHistoryScreen/index.jsx';
import { Refresh, SpeakerNotes } from '@material-ui/icons';
import JobNotesModal from '../workflow-job/JobNotes.jsx';
import { GetUserPreferenceWorkflowsList } from '../../../Core/Service/dashbordService.js';
import FilterModalMainTabComponent from './FilterModalMainTabComp.jsx';
import { DatePicker, MultipleSelectWithMoreOp } from '../../../Core/FormInput/index.jsx';
import { GetBranchDeportmentScreenDropdowns } from '../../../Core/Service/reportService.js';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb/index.jsx';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Assigned Jobs', active: true }
];

const useStyles = (props) =>
    makeStyles((thems) => {
        return {
            ...props
        };
    });

let config = {
    // act: {
    //     from: '',
    //     to: '',
    //     getSequence: 'actionSequence',
    //     postSequence: 'actionSequence'
    // },
    wipNumber: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo',
        getSequence: 'wipNoSequence',
        postSequence: 'WIPNoSequence'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg',
        getSequence: 'vehicleRegSequence',
        postSequence: 'VehicleRegSequence'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel',
        getSequence: 'modelSequence',
        postSequence: 'modelSequence'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer',
        getSequence: 'customerSequence',
        postSequence: 'customerSequence'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn',
        getSequence: 'dateDueInSequence',
        postSequence: 'dateDueInSequence'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut',
        getSequence: 'dateDueOutSequence',
        postSequence: 'dateDueOutSequence'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason',
        getSequence: 'returnReasonSequence',
        postSequence: 'returnReasonSequence'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch',
        getSequence: 'branchSequence',
        postSequence: 'branchSequence'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise',
        getSequence: 'franchiseSequence',
        postSequence: 'franchiseSequence'
    },
    st: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription',
        getSequence: 'jobDescriptionSequence',
        postSequence: 'jobDescriptionSequence'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount',
        getSequence: 'accountSequence',
        postSequence: 'accountSequence'
    },
    cb: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy',
        getSequence: 'createdBySequence',
        postSequence: 'createdBySequence'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate',
        getSequence: 'assignedDateSequence',
        postSequence: 'assignedDateSequence'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken',
        getSequence: 'timeTakenSequence',
        postSequence: 'timeTakenSequence'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus',
        getSequence: 'statusSequence',
        postSequence: 'statusSequence'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays',
        getSequence: 'assignedDaysSequence',
        postSequence: 'assignedDaysSequence'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator',
        getSequence: 'owningOperatorSequence',
        postSequence: 'owningOperatorSequence'
    },
    jobAssignedUser: {
        from: 'displayJobAssignedUser',
        to: 'UserGridColumnDisplayJobAssignedUser',
        getSequence: 'jobAssignedUserSequence',
        postSequence: 'jobAssignedUserSequence'
    },
    chassisNumber: {
        from: 'displayChassisNumber',
        to: 'UserGridColumnDisplayChassisNumber',
        getSequence: 'chassisNumberSequence',
        postSequence: 'chassisNumberSequence'
    },
    courtesyCarReq: {
        from: 'displayCourtesyCarReq',
        to: 'UserGridColumnDisplayCourtesyCarReq',
        getSequence: 'courtesyCarReqSequence',
        postSequence: 'courtesyCarReqSequence'
    },
    customerWaiting: {
        from: 'displayCustomerWaiting',
        to: 'UserGridColumnDisplayCustomerWaiting',
        getSequence: 'customerWaitingSequence',
        postSequence: 'customerWaitingSequence'
    },
    wipAge: {
        from: 'displayWIPAge',
        to: 'UserGridColumnDisplayWIPAge',
        getSequence: 'wipAgeSequence',
        postSequence: 'wipAgeSequence'
    },

    isFMOrWarranty: {
        from: 'displayIsFMOrWarranty',
        to: 'UserGridColumnDisplayIsFMOrWarranty',
        getSequence: 'isFMOrWarrantySequence',
        postSequence: 'isFMOrWarrantySequence'
    },
    isBreakdown: {
        from: 'displayIsBreakdown',
        to: 'UserGridColumnDisplayIsBreakdown',
        getSequence: 'isBreakdownSequence',
        postSequence: 'isBreakdownSequence'
    },
    isNightShift: {
        from: 'displayIsNightShift',
        to: 'UserGridColumnDisplayIsNightShift',
        getSequence: 'isNightShiftSequence',
        postSequence: 'isNightShiftSequence'
    }
};

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

const ratingList = {
    1: <StarIcon style={{ color: 'gold' }} size="small" />,
    2: <StarIcon style={{ color: '#C0C0C0' }} size="small" />,
    3: <StarIcon style={{ color: '#CD7F32' }} size="small" />
};

let AssignedJobUserGrid = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const workflowId = params.get('workflowId');
    const ProcessID = params.get('ProcessID');
    const selectedUserId = params.get('assignedUserID');

    const [state, setState] = useState({
        workflowButtons: [],
        rows: [],
        filteredRows: [],
        totalRec: 0,
        filterText: '',
        btnSelectedId: props.location.query?.workFlowId || localStorage.getItem('currentWorkflowId'),
        currentWorkflowId: workflowId || '',
        selectedUserId: selectedUserId || '',
        returnReasonID: props.location.query?.returnReasonID || '',
        hasQueryValues: props.location.query?.returnReasonID ? true : false,
        tempObj: {},
        filterList: [],
        displayPagination: true,
        showTechAssign: false,
        isReload: null,
        timeID: JSON.parse(localStorage.getItem('FiltterParams'))?.branchTime || '',
        dueTime: JSON.parse(localStorage.getItem('FiltterParams'))?.dueTime || '',
        logHistory: false,
        displayJobPriorityButton: false,
        showCustomerRating: false,
        displayCustomerRatingButton: false,
        displayCustomerMessage: false,
        UserPreferenceWorkflowsList: [],
        fromReports: params.get('fromReports') || 'No',
        startDate: params.get('startDate') || '',
        endDate: params.get('endDate') || '',
        branchList: [],
        showJobHeader: {}
    });

    // const { t, i18n, langLabel } = Customi18();
    const [loading, setLoading] = React.useState(false);
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
    const canSetCustomerRating = AppStorage.getCanSetCustomerRating();
    const pullGridFilterList = async () => {
        // let timeID = JSON.parse(localStorage.getItem('FiltterParams'));
        let data = await getUserPreference();
        if (data) {
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination),
                showJobHeader: {
                    showHeaderWIPNumber: data.details[0]?.showHeaderWIPNumber || false,
                    showHeaderRegNumber: data.details[0]?.showHeaderRegNumber || false,
                    showHeaderCustomer: data.details[0]?.showHeaderCustomer || false,
                    showHeaderKeyNumber: data.details[0]?.showHeaderKeyNumber || false,
                    showHeaderChassisNumber: data.details[0]?.showHeaderChassisNumber || false,
                    showHeaderOutstandingPayment: data.details[0]?.showHeaderOutstandingPayment || false,
                    showHeaderCreditLimit: data.details[0]?.showHeaderCreditLimit || false,
                    showHeaderWorkDetails: data.details[0]?.showHeaderWorkDetails || false,
                    showHeaderCashMessage: data.details[0]?.showHeaderCashMessage || false
                }
                // timeID: timeID?.branchTime
            }));
        }
    };

    // useEffect(() => {
    //     if (props.location.query) {
    //         setState((st) => ({
    //             ...st,
    //             returnReasonID: props.location.query.returnReasonID,
    //             filterText: props.location.query.returnReason,
    //             btnSelectedId: props.location.query?.workFlowId,
    //             hasQueryValues: true
    //         }));
    //     }
    // }, []);

    const pullWorkFlowsButtons = async () => {
        setLoading(true);
        let res = await getWorkflowsByProcessID(1);
        if (res.success) {
            const buttons = res.data.map((w) => ({
                id: w.id,
                label: w.workflowName,
                displayJobPriorityButton: w.displayJobPriorityButton == 'Y' ? true : false,
                displayCustomerRatingButton: w.displayCustomerRatingButton == 'Y' ? true : false
            }));
            let resPreference = await GetUserPreferenceWorkflowsList(ProcessID);

            setState((st) => ({ ...st, workflowButtons: buttons, UserPreferenceWorkflowsList: resPreference.data.list }));
        }
    };

    useEffect(async () => {
        let res = await GetBranchDeportmentScreenDropdowns();
        if (res.success) {
            setState((st) => ({
                ...st,
                branchList: res.data?.branchList || []
            }));
        }
        pullWorkFlowsButtons();
    }, []);

    useEffect(() => {
        if (isAppReaload) {
            pullGridFilterList();
        }
    }, [isAppReaload]);

    var colorCodeObj = {};
    state.filteredRows.forEach((c) => {
        colorCodeObj[[c.id]] = {
            background: c.returnReasonColourCode,
            color: c.returnReasonTextColourCode
        };
    });
    const classes = useStyles(colorCodeObj)();

    const handleAssignmentBtnClick = (val) => {
        // props.history.push(`/assign-technician/${val.headerRecordID}`);

        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };

    const custerRating = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            workFlowData: val,
            showCustomerRating: true
        }));
    };

    const handleCustmer = (val) => {
        setState((st) => ({
            ...st,
            customerMessage: val,
            displayCustomerMessage: true
        }));
    };

    const handleShowJobPriorityBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            workFlowData: val,
            showJobPriority: true
        }));
    };

    const handleLogHistory = (val) => {
        setState((st) => ({
            ...st,
            logHistory: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const handleJobNotes = (val, on) => {
        setState((st) => ({
            ...st,
            jobNotes: on,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="left">
                {/* <Grid>
                    <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                        <ArrowForwardIosIcon
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                </Grid> */}
                {isCommunicatorAllowed && (
                    <Grid style={{ marginLeft: '13px 5px' }}>
                        <Tooltip title="Log History">
                            <IconButton size="small" onClick={() => handleLogHistory(val)}>
                                <AssignmentIcon
                                    style={{
                                        color: val.returnReasonTextColourCode
                                    }}
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <Grid style={{ marginLeft: '13px 5px' }}>
                    <Tooltip title="Job Notes">
                        <IconButton size="small" onClick={() => handleJobNotes(val, true)}>
                            <SpeakerNotes style={{ color: val.hasNote ? 'gold' : val.returnReasonTextColourCode }} fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid>
                    {canSetCustomerRating ? (
                        <>
                            {val.customerRatingIcon ? (
                                <Tooltip title={val.customerRatingNotes}>
                                    <IconButton size="small" onClick={() => custerRating(val)}>
                                        {ratingList[val.customerRatingIcon]}
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Set Customer Rating">
                                    <IconButton
                                        size="small"
                                        style={{
                                            color: val.returnReasonTextColourCode
                                        }}
                                        onClick={() => custerRating(val)}
                                    >
                                        <ReportIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        state.displayCustomerRatingButton && (
                            <Tooltip title={val.customerRatingNotes}>
                                <IconButton
                                    size="small"
                                    style={{
                                        color: val.returnReasonTextColourCode
                                    }}
                                    onClick={() => handleCustmer(val.customerRatingNotes)}
                                >
                                    {ratingList[val.customerRatingIcon]}
                                </IconButton>
                            </Tooltip>
                        )
                    )}
                    {/* {val.customerRatingIcon ? (
                        <Tooltip title={val.customerRatingNotes}>
                            <IconButton size="small" onClick={() => handleCustmer(val.customerRatingNotes)}>
                                {ratingList[val.customerRatingIcon]}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        state.displayCustomerRatingButton && (
                            <Tooltip title="Set Customer Rating">
                                <IconButton size="small" onClick={() => custerRating(val)}>
                                    <ReportIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )} */}
                </Grid>

                <Grid>
                    <Tooltip title="Set Priority">
                        <IconButton
                            size="small"
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            onClick={state.displayJobPriorityButton ? () => handleShowJobPriorityBtnClick(val) : () => false}
                        >
                            {val.priorityNumber == 999999 && state.displayJobPriorityButton ? (
                                <LowPriorityIcon fontSize="small" />
                            ) : (
                                <>{val.priorityNumber < 999999 ? val.priorityNumber : ''}</>
                            )}
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid>
                    {val.technicians && (
                        // <Tooltip title={val.technicians} aria-label="add" disableFocusListener>
                        <IconButton title={`${val.technicians}`} size="small" onClick={() => state.currentWorkflowId == 8 && handleAssignmentBtnClick(val)}>
                            <AssignmentIndIcon
                                style={{
                                    color: val.returnReasonTextColourCode
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                        // </Tooltip>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: 'act',
            renderer: editButton,
            width: 95,
            filterable: false
        },
        { field: 'wipNumber', sortable: true, title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`, width: 100 },
        { field: 'makeModel', sortable: true, title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`, width: 200 },
        { field: 'customerName', sortable: true, title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`, width: 170 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Due Date In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        { field: 'returnReason', sortable: true, title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`, width: 190 },
        { field: 'chassisNumber', sortable: true, title: 'Chassis Number', width: 180 },
        { field: 'branchShortName', sortable: true, title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`, width: 120 },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'st', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        { field: 'cb', sortable: true, title: `${portalSettings.portalSettingGridCreatedByLabel || 'Created By'}`, width: 100 },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 110
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 130
        },
        {
            field: 'jobAssignedUser',
            sortable: true,
            title: `${portalSettings.portalSettingGridJobAssignedUserLabel || 'Assigned User'}`,
            width: 110
        },
        {
            field: 'courtesyCarReq',
            sortable: true,
            title: `${portalSettings.portalSettingGridCourtesyCarReqLabel || 'Loan Vehicle'}`,
            width: 140
        },
        {
            field: 'customerWaiting',
            sortable: true,
            title: `${portalSettings.portalSettingGridCustomerWaitingLabel || 'Customer Waiting'}`,
            width: 140
        },
        {
            field: 'wipAge',
            sortable: true,
            title: `${portalSettings.portalSettingGridWIPAgeLabel || 'WIP Age'}`,
            width: 80
        },
        {
            field: 'isFMOrWarranty',
            sortable: true,
            title: `${portalSettings.portalSettingGridIsFMOrWarrantyLabel || 'Is FM Or Warranty'}`,
            width: 80
        },
        {
            field: 'isBreakdown',
            sortable: true,
            title: `${portalSettings.portalSettingGridIsBreakdownLabel || 'Is Breakdown'}`,
            width: 80
        },
        {
            field: 'isNightShift',
            sortable: true,
            title: `${portalSettings.portalSettingGridIsNightShiftLabel || 'Is Night Shift'}`,
            width: 80
        }
    ];

    // if (state.currentWorkflowId === 'All') {
    // baseColumns.push(
    // {
    //     field: 'wn',
    //     sortable: true,
    //     isShow: true,
    //     title: `Workflow Name`,
    //     width: 160
    // }
    // );
    // }

    baseColumns.splice(1, 0, {
        field: 'wn',
        sortable: true,
        isShow: true,
        title: `Workflow Name`,
        width: 160
    });

    const regNumberRendrer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.registration, FranchiseCode: record?.f }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    let Column = baseColumns
        .map((c) => {
            let obj = {};
            if (Object.keys(state.tempObj).length > 0 && !c.isShow) {
                obj.sequenceNo = state.tempObj[config[c.field]?.getSequence] || 9999999;
                if (c.title !== 'Action') {
                    obj.hidden = !state.tempObj[config[c.field]?.from];
                }
            } else {
                obj.hidden = false;
            }
            if (c.title !== 'Action' && c.field !== 'registration' && c.field !== 'chassisNumber') {
                obj.events = {
                    click: function (e) {
                        props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}?ProcessId=${e.data.record.pid}`);
                    }
                };
            }
            if (c.field == 'registration') {
                c.renderer = regNumberRendrer({});
            }
            if (c.title == 'Action') {
                obj.sequenceNo = -1;
            }
            return {
                ...c,
                ...obj,
                headerCssClass: 'gridHeader'
            };
        })
        .sort((a, b) => {
            return a.sequenceNo - b.sequenceNo;
        });

    const handleOpen = () => {
        let tempArr = [...Column]
            .map((q) => {
                return {
                    field: q.field,
                    title: q.title,
                    hidden: !q.hidden,
                    fieldName: config[q.field]?.to,
                    isShow: q.isShow ? q.isShow : false,
                    postSequenceFieldName: config[q.field]?.postSequence
                };
            })
            .filter((p) => (!p.isShow && p.title != 'Action' ? p : null));
        // tempArr.push({
        //     field: 'pagination',
        //     title: 'Pagination',
        //     hidden: state.displayPagination,
        //     fieldName: 'UserGridColumnDisplayPagination'
        // });

        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const closeDialog = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showFilter = false;
            newSt.showTechAssign = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });
        res && pullGridFilterList();
        res && pullWorkFlowsButtons();
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showJobPriority = false;
            newSt.showTechAssign = false;
            newSt.showCustomerRating = false;
            newSt.displayCustomerMessage = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });
    };

    // let getUrl = `WorkflowJobs/GetWorkflow/${state.currentWorkflowId === 'All' ? '' : state.currentWorkflowId}?${
    //     state.returnReasonID ? `ReturnReasonID=${state.returnReasonID}` : ''
    // }${state.timeID ? `&Time=${state.timeID}` : ''}`;
    useEffect(async () => {
        if (portalSettings.portalSettingGridRefreshTime > 0) {
            const intervalId = await setInterval(() => {
                setState((st) => {
                    const newSt = { ...st };
                    newSt.isReload = new Date();
                    return newSt;
                });
            }, portalSettings.portalSettingGridRefreshTime * 1000);
            return () => clearInterval(intervalId);
        }
    }, [portalSettings.portalSettingGridRefreshTime]);

    useEffect(() => {
        let tempUrl = `WorkflowJobs/GetAssignedJobs?workflowId=${state.currentWorkflowId}&assignedUserID=${state.selectedUserId}`;

        setState((st) => {
            const newSt = { ...st };
            newSt.getUrl = tempUrl;
            newSt.isReload = new Date();
            return newSt;
        });
    }, [state.currentWorkflowId]);

    useEffect(() => {
        setState((st) => {
            const newSt = { ...st };
            newSt.isGridColumnReload = new Date();
            return newSt;
        });
    }, [state.displayJobPriorityButton]);

    const modalSubHeaderTitle = () => {
        return (
            <div>
                <span style={{ fontSize: '15px' }}>
                    WIP No: <b>{state.wip}</b> Reg No: <b>{state.reg}</b>
                </span>
            </div>
        );
    };
    const handleChanges = (event, value) => {
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    return (
        <div className="filter-Container">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <BreadCrumbs crumbs={crumbs} />
                <span
                //  className="filter-div"
                >
                    <IconButton aria-label="filter" onClick={handleOpen} style={{ padding: '0px' }}>
                        <FilterListIcon />
                    </IconButton>
                </span>
            </div>

            <div>
                {state.getUrl && (
                    <GijgoGrid
                        columns={Column}
                        getUrl={state.getUrl}
                        currentWorkflowLabel={'Assigned Jobs'}
                        currentWorkflowId={state.currentWorkflowId}
                        filterList={state.tempObj}
                        displayPagination={state.displayPagination}
                        isReload={state.isReload}
                        isGridColumnReload={state.isGridColumnReload}
                    />
                )}
            </div>
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="lg" onClose={() => closeDialog(false)} overflow="auto" fullWidth>
                    <FilterModalMainTabComponent
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                        isPagination={state.displayPagination}
                        showJobHeader={state.showJobHeader}
                        ProcessID={ProcessID}
                    />
                    {/* <GridFilterModal
                        UserPreferenceWorkflowsList={state.UserPreferenceWorkflowsList}
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                    /> */}
                </DialogComp>
            ) : null}
            {state.showTechAssign ? (
                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.showJobPriority ? (
                <DialogComp title="Job Priority" maxWidth="xs" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <JobPriorityModal jobId={state.jobId} workFlowData={state.workFlowData} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.showCustomerRating ? (
                <DialogComp title="Customer Rating" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <CustomerRating jobId={state.jobId} workFlowData={state.workFlowData} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.jobNotes ? (
                <JobNotesModal
                    modalSubHeaderTitle={modalSubHeaderTitle}
                    maxWidth="md"
                    jobId={state.headerRecordID}
                    onClose={() => handleJobNotes([], false)}
                    onNotesAdded={() => {
                        console.log('');
                    }}
                />
            ) : null}
            {state.displayCustomerMessage ? (
                <DialogComp title="Customer Rating Message" maxWidth="xs" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <Typography variant="h6" style={{ textAlign: 'center' }} color="secondary">
                        {state.customerMessage}
                    </Typography>
                </DialogComp>
            ) : null}
            {state.logHistory ? (
                <LogHistory
                    headerRecordID={state.headerRecordID}
                    reg={state.reg}
                    wip={state.wip}
                    showDialog={state.logHistory}
                    onClose={() => {
                        setState((st) => ({
                            ...st,
                            logHistory: false
                        }));
                    }}
                />
            ) : null}
            {state.showMotHistory && (
                <DialogComp
                    title="MOT History"
                    onClose={() => setState((st) => ({ ...st, showMotHistory: false }))}
                    maxWidth="md"
                    fullWidth
                    overflow={'auto'}
                    height="680px"
                >
                    <MotHistoryScreen regNo={state.regNo} FranchiseCode={state.FranchiseCode} hideSeachText={true} />
                </DialogComp>
            )}
        </div>
    );
};
export default AssignedJobUserGrid;
