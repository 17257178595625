import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { AppStorage } from '../../../Core/Service/storage-service';
import { ExpBaseURL } from '../../../Core/Service/http-calls';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import './dashboard.scss';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { getBranchAndFranchise } from '../../../Core/Service/branch-screen-service';
import { GetArchivedJobsExcelFile } from '../../../Core/Service/workflow-service';
import AppContext from '../../../App/AppContext';

var grid1;
var $ = window.$;
let storedFiltterValue = {};
let branchList = [];
let franchiseList = [];

const GijgoGrid = (props) => {
    const token = AppStorage.getToken();
    const [state, setState] = useState({});
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { hideModal, showToast, portalSettings } = useContext(AppContext);

    useEffect(() => {
        console.log('opopopo');
        if (grid1 && grid1.data().columns) {
            grid1.data().columns = [...props.columns];
        }
    }, [props.isGridColumnReload]);

    useEffect(async () => {
        if (token) {
            pullArchivedJobsAndUpdateState();
        } else {
            window.location = '/';
            AppStorage.performLogout();
        }
    }, [token, props.filterList, props.isReload]);

    useEffect(async () => {
        if (token) {
            let res = await getBranchAndFranchise();
            if (res.success) {
                branchList = res.data.branches;
                franchiseList = res.data.franchiseRecords;
                props.parentSetbranchList && props.parentSetbranchList(res.data.branches);
            }
        }
    }, [token]);

    var GijgoFilterExtension = (function () {
        function DropdownFilter(grid, propertyName, dropdown, changeValueConversion) {
            var id = $(grid).attr('id');
            var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
            var filterRow = $(rowXPath);
            if ($(filterRow).length == 1) {
                var colXPath = 'th [data-field="' + propertyName + '"]';
                var filterInput = $(filterRow).find(colXPath);
                if ($(filterInput).length) {
                    $(filterInput).parent().addClass('grid-filter-select-th');
                    $(dropdown).attr('data-field', propertyName);
                    $(filterInput).replaceWith(dropdown);
                    $(dropdown).on('change', function () {
                        ChangeFilter(grid, propertyName, $(this), changeValueConversion);
                    });
                }
            }
        }
        function MultiDropdownFilter(grid, propertyName, dropdown, changeValueConversion) {
            // Generate unique IDs for this instance
            const uniqueId = `checkboxes_${propertyName}_${Math.random().toString(36).substr(2, 9)}`;
            dropdown.find('#checkboxes').attr('id', uniqueId);

            // Update the structure with unique ID
            var expanded = false;
            $('.multiselect-overlay').remove();
            $(`#${uniqueId}`).remove();

            // Setup the dropdown in the grid
            var id = $(grid).attr('id');
            var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
            var filterRow = $(rowXPath);

            if ($(filterRow).length == 1) {
                var colXPath = 'th [data-field="' + propertyName + '"]';
                var filterInput = $(filterRow).find(colXPath);

                if ($(filterInput).length) {
                    $(filterInput).parent().addClass('grid-filter-select-th');
                    $(dropdown).attr('data-field', propertyName);
                    $(filterInput).replaceWith(dropdown);

                    // Check for stored values and set checkboxes
                    if (storedFiltterValue[propertyName]) {
                        const storedValues = storedFiltterValue[propertyName].split(',');

                        // Update the select display text
                        var displayText = storedValues.length > 0 ? storedValues.join(', ') : `Select ${propertyName}(s)`;
                        dropdown.find('.selectBox select').empty().append($('<option></option>').text(displayText)).val(displayText);

                        // Set the checkboxes as checked
                        dropdown.find(`#${uniqueId} input[type="checkbox"]`).each(function () {
                            const checkboxValue = $(this).parent().text().trim();
                            if (storedValues.includes(checkboxValue)) {
                                $(this).prop('checked', true);
                            }
                        });
                    }

                    const handleDropdownClose = () => {
                        dropdown.find(`#${uniqueId}`).hide();
                        dropdown.find('.multiselect-overlay').hide();
                        expanded = false;
                    };

                    // Handle overlay click
                    dropdown
                        .find('.multiselect-overlay')
                        .off('click.multiselect')
                        .on('click.multiselect', function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDropdownClose();
                        });

                    // Handle checkbox container clicks
                    dropdown
                        .find(`#${uniqueId}`)
                        .off('click.multiselect')
                        .on('click.multiselect', function (e) {
                            e.stopPropagation();
                        });

                    // Handle checkbox changes
                    dropdown
                        .find(`#${uniqueId} input[type="checkbox"]`)
                        .off('change.multiselect')
                        .on('change.multiselect', function (e) {
                            e.stopPropagation();
                            let selectedOptions = [];

                            dropdown.find(`#${uniqueId} input[type="checkbox"]:checked`).each(function () {
                                selectedOptions.push($(this).parent().text().trim());
                            });

                            // Update FiltterParams
                            storedFiltterValue = JSON.parse(localStorage.getItem('FiltterParams')) || {};
                            storedFiltterValue[propertyName] = selectedOptions.join(',');
                            localStorage.setItem('FiltterParams', JSON.stringify(storedFiltterValue));

                            // Update the select input value
                            var displayText = selectedOptions.length > 0 ? selectedOptions.join(', ') : `Select ${propertyName}(s)`;
                            dropdown.find('.selectBox select').empty().append($('<option></option>').text(displayText)).val(displayText);

                            // Update grid params
                            grid.data().params[propertyName] = storedFiltterValue[propertyName];
                            grid.reload();
                        });

                    // Handle select box click
                    dropdown
                        .find('.selectBox')
                        .off('click.multiselect')
                        .on('click.multiselect', function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            var checkboxes = dropdown.find(`#${uniqueId}`);
                            var selectBox = $(this);
                            var overlay = dropdown.find('.multiselect-overlay');

                            if (!expanded) {
                                handleDropdownClose();
                                var selectBoxPos = selectBox[0].getBoundingClientRect();
                                overlay.show();
                                checkboxes.css({
                                    display: 'block',
                                    position: 'fixed',
                                    top: selectBoxPos.bottom + 1 + 'px',
                                    left: selectBoxPos.left + 'px',
                                    width: selectBoxPos.width + 'px'
                                });
                                expanded = true;
                            } else {
                                handleDropdownClose();
                            }
                        });
                }
            }
        }

        function ChangeFilter(grid, propertyName, ddl, changeValueConversion) {
            if (changeValueConversion) {
                var propArr = changeValueConversion(ddl, propertyName, grid);
                $(propArr).each(function (i, obj) {
                    grid.data().params[obj.propertyName] = obj.value;
                });
                if (propArr.length) {
                    grid.reload();
                }
            } else {
                grid.data().params[propertyName] = $(ddl).val();
                grid.reload();
            }
        }

        return {
            Dropdown: DropdownFilter,
            MultiDropdown: MultiDropdownFilter
        };
    })();

    const setDataSource = (gridInstance, getUrl) => {
        const onSuccessFunc = function (response) {
            gridInstance.render({
                records: response.data.rows,
                total: response.data.total
            });
        };

        gridInstance.data().dataSource = {
            url: `${ExpBaseURL}/api/${getUrl}`,
            headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            success: onSuccessFunc
        };
    };

    const pullArchivedJobsAndUpdateState = async () => {
        window.$('#grid').grid('destroy', true, true);

        grid1 = window.$('#grid').grid({
            primaryKey: 'wipNo',
            dataSource: [],
            width: '100%',
            headerFilter: {
                type: 'onchange'
            },
            columns: [...props.columns],
            pager: props.displayPagination ? { limit: 100, sizes: [10, 50, 100, 500, 1000, 2000, 5000] } : false
        });

        setTimeout(() => {
            console.log('Zoro');
            storedFiltterValue = JSON.parse(localStorage.getItem('FiltterParams')) || {};
            props.columns.forEach((itm) => {
                if (storedFiltterValue) {
                    var id = $(grid1).attr('id');
                    var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
                    var filterRow = $(rowXPath);
                    if ($(filterRow).length == 1) {
                        var colXPath = 'th [data-field="' + `${itm.field}` + '"]';
                        var filterInput = $(filterRow).find(colXPath);
                        if ($(filterInput).length && storedFiltterValue[`${itm.field}`]) {
                            $(filterInput).val(storedFiltterValue[`${itm.field}`]);
                            grid1.data().params[itm.field] = storedFiltterValue[`${itm.field}`];
                        }
                    }
                }
            });
            if (storedFiltterValue.sortBy && storedFiltterValue.direction) {
                grid1.data().params.sortBy = storedFiltterValue.sortBy;
                grid1.data().params.direction = storedFiltterValue.direction;
            }

            /*
            set the data source of the grid just once, and then trigger
            the reload, to pull the latest data with filter if any
            */
            setDataSource(grid1, props.getUrl);
            grid1.reload();
        });

        grid1.on('dataBound', function (e, records, totalRecords) {
            console.log('luffy');
            let { page, limit, ...rest } = grid1.data().params;
            let tempArray = storedFiltterValue;
            for (const property in rest) {
                tempArray = {
                    ...tempArray,
                    [`${property}`]: rest[property]
                };
            }
            localStorage.setItem('FiltterParams', JSON.stringify(tempArray));

            var ddl = $('<select class=" grid-filter-select"><option value="" ></option></select>');
            var supCd = grid1.data().params.branchShortName || '';
            $(branchList).each(function (i, itm) {
                if (supCd.length > 0 && itm.branchShortName == supCd) {
                    ddl.append(`<option value="${itm.branchShortName}" selected >${itm.branchShortName}</option>`);
                } else {
                    ddl.append(`<option value="${itm.branchShortName}" >${itm.branchShortName}</option>`);
                }
            });
            GijgoFilterExtension.Dropdown(grid1, 'branchShortName', ddl);

            // Create the multiselect dropdown
            var ghus = $(
                `<div class="multiselect">
                    <div class="selectBox">
                        <select class="grid-filter-select" readonly>
                            <option value="">Select franchise(s)</option>
                        </select>
                        <div class="overSelect"></div>
                    </div>
                    <div class="multiselect-overlay"></div>
                    <div id="checkboxes" style="display: none;"></div>
                </div>`
            );

            // Add franchise options
            $(franchiseList).each(function (i, itm) {
                ghus.find('#checkboxes').append(`
                    <label>
                        <input type="checkbox" value="${itm.franchiseName}" />
                        ${itm.franchiseName}
                    </label>
                `);
            });
            GijgoFilterExtension.MultiDropdown(grid1, 'franchiseName', ghus);
        });

        grid1.on('rowDataBound', function (e, $row, id, record) {
            $row.css('background-color', `white`);
            if (record.highPriority) {
                $row.css('background-color', `${portalSettings.portalHighPriorityJobColour}`);
            } else if (record.returnReasonColourCode) {
                $row.css('background-color', `${record.returnReasonColourCode}`);
            } else {
                const dtDueIn = moment(record.dateDueIn).format('MM/DD/YYYY');
                const daysPast = moment().diff(dtDueIn, 'days');
                if (daysPast > 40) {
                    $row.css('background-color', 'orange');
                } else if (daysPast > 5) {
                    $row.css('background-color', 'yellow');
                }
            }
        });
        grid1.on('cellDataBound', function (e, $displayEl, id, column, record) {
            $displayEl.css('color', `${record.returnReasonTextColourCode}`);
        });
        // grid1.reload();
    };

    const downloadCSVWrapper = async () => {
        if (!props.getUrl.includes('GetWorkflow')) {
            grid1?.downloadCSV(`${props.currentWorkflowLabel}-${moment().format('DD-MMM-YYYY HH:mm')}.csv`);
        } else {
            showToast('Collating Data... This may take a while.');

            const workflowID = localStorage.getItem('currentWorkflowId');
            const data = grid1.data().params;

            let params = `/${workflowID == 'All' ? '' : workflowID}?`;

            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key];
                    params += `&${key}=${value}`;
                }
            }

            await GetArchivedJobsExcelFile(params);
        }
    };

    return (
        <Grid container className="common-gijgo-grid" style={{ overflowX: 'auto', position: 'relative' }}>
            <Grid item style={{ top: '33px', left: '18px', zIndex: 99, position: 'absolute' }}>
                <Tooltip title="Download CSV" arrow>
                    <IconButton aria-label="delete">
                        <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} />
                    </IconButton>
                </Tooltip>
            </Grid>

            <Grid container item xs={12} sm={12} style={{ margin: '10px auto' }}>
                <table id="grid"></table>
            </Grid>
        </Grid>
    );
};
export default GijgoGrid;
