import { get, post } from './http-calls';

export let getPassedPeriod = async () => {
    let data = await get(`NominalAccountHistory/GetPastPeriodData`, { useAuthToken: true });
    return data;
};
export let getCurrentPeriodData = async () => {
    let data = await get(`NominalAccountHistory/GetCurrentPeriodData`, { useAuthToken: true });
    return data;
};
export let getFuturePeriodData = async () => {
    let data = await get(`NominalAccountHistory/GetFuturePeriodData`, { useAuthToken: true });
    return data;
};