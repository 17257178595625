import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import { getLoanVehicleUsage, LoanVehicleSearch, postLoanVehicleUsage } from '../../../Core/Service/loanVehicleUsage-service';
import AppContext from '../../../App/AppContext';
import { Header } from './../../../Core/Header/index';

export const LoanVehicleContext = createContext();

export const LoanVehicleProvider = (props) => {
    let HeaderRecordID = props?.match?.params?.HeaderRecordID;

    const urlParams = new URLSearchParams(props.location?.search?.toLowerCase());
    const navigateToDashboard = urlParams?.get('navigate') === 'true';

    const [state, setState] = useState({
        handOverCustomerSignature: '',
        handoverCustomerSignatureOut: '',
        handoverStaffSignatureOut: '',
        handoverCustomerSignatureIn: '',
        handoverStaffSignatureIn: '',
        loading: true,
        loanVehicleList: [],
        isVehicleInState: false,
        isUpdate: false,
        isVehicleReceived: false
    });
    const [companyDetails, setCompanyDetails] = useState({
        companyContactName: '',
        companyContactEmail: '',
        companyContactCompany: '',
        companyContactNumber: '',
        companyContactAddress: '',
        companyContactPostCode: '',
        companyContactReasonForUse: '',
        companyDriverName: '',
        companyRegistrationNumber: '',
        companyWIPNumber: null
    });

    const [loading, setLoading] = useState(false);

    const { showToast } = useContext(AppContext);

    const [insuranceDetails, setInsuranceDetails] = useState({
        insuranceCompanyName: '',
        insuranceCompanyAddress: '',
        insuranceCompanyPolicyNumber: '',
        insurancePolicyStartDate: null,
        insurancePolicyRenewalDate: null
    });

    const [bookingDetails, setBookingDetails] = useState({
        dateOut: null,
        dateIn: null,
        estimatedDateDueIn: null,
        mileageOut: null,
        mileageIn: null,
        fuelOut: null,
        fuelIn: null
    });

    const [handOverDetails, setHandOverDetails] = useState({
        handOverCustomerSignature: '',
        handedOverName: '',
        handoverCustomerSignatureOut: '',
        handoverStaffSignatureOut: '',
        handoverCustomerSignatureIn: '',
        handoverStaffSignatureIn: ''
    });

    const [loanVehicleInImages, setLoanVehicleInImages] = useState([]);
    const [loanVehicleOutImages, setLoanVehicleOutImages] = useState([]);

    const [vehicleDetails, setVehicleDetails] = useState({
        dateCreated: moment().format('YYYY-MM-DD'),
        vehicleModel: '',
        insuranceValue: null,
        loanVehicleList: [],
        vehicleReg: ''
    });

    const [errors, setErrors] = useState({});

    const handleVehicleDetails = (e) => {
        const { name, value } = e.target;
        setVehicleDetails({ ...vehicleDetails, [name]: value });
        setState((prev) => ({ ...prev, isUpdate: true }));
    };

    const handleCompanyContactDetails = (e) => {
        const { name, value } = e.target;
        setCompanyDetails({ ...companyDetails, [name]: value });
    };

    const handleInsuranceDetails = (e) => {
        const { name, value } = e.target;
        setInsuranceDetails({ ...insuranceDetails, [name]: value });
    };

    const handleBookingDetails = (e) => {
        const { name, value } = e.target;
        setBookingDetails({ ...bookingDetails, [name]: value });
        validateMileage(name, value);
    };
    const handleHandOverDetails = (e) => {
        const { name, value } = e.target;
        setHandOverDetails({ ...handOverDetails, [name]: value });
    };

    const fieldChange = (val) => {
        setHandOverDetails((st) => ({
            ...st,
            ...val
        }));
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            partApproverSignature: null
        }));
    };

    const searchLoanVehicle = async () => {
        let res = await LoanVehicleSearch(vehicleDetails.vehicleReg);
        if (res.success) {
            let data = res?.data?.list || [];
            setVehicleDetails((prev) => ({
                ...prev,
                loanVehicleList: data || []
            }));
        }
    };

    const getRegNubmer = (obj) => {
        if (obj.headerRecordID) {
            const newPath = `/Job/${obj.headerRecordID}/${obj.workflowID}?ProcessId=1`;
            const url = `${window.location.origin}${newPath}`;
            window.open(url, '_blank');
        }
        setVehicleDetails((prev) => ({
            ...prev,
            loanVehicleID: obj.loavVehicleID,
            vehicleReg: obj.regNo,
            vehicleModel: obj.vehicleModel
        }));
        setState((prev) => ({ ...prev, isUpdate: false }));
    };

    const handleCancel = () => {
        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
    };

    useEffect(() => {
        let fetchData = async () => {
            try {
                if (HeaderRecordID) {
                    let res = await getLoanVehicleUsage(HeaderRecordID);

                    if (res.success) {
                        let data = res?.data?.detail[0] || {};
                        setCompanyDetails({
                            companyContactName: data.companyContactName,
                            companyContactEmail: data.companyContactEmail,
                            companyContactCompany: data.companyContactCompany,
                            companyContactNumber: data.companyContactNumber,
                            companyContactAddress: data.companyContactAddress,
                            companyContactPostCode: data.companyContactPostCode,
                            companyContactReasonForUse: data.companyContactReasonForUse,
                            companyDriverName: data.companyDriverName,
                            companyRegistrationNumber: data.companyRegistrationNumber,
                            companyWIPNumber: data.companyWIPNumber
                        });

                        // Update Insurance Details
                        setInsuranceDetails({
                            insuranceCompanyName: data.insuranceCompanyName,
                            insuranceCompanyAddress: data.insuranceCompanyAddress,
                            insuranceCompanyPolicyNumber: data.insuranceCompanyPolicyNumber,
                            insurancePolicyStartDate: data.insurancePolicyStartDate ? moment(data.insurancePolicyStartDate).format('YYYY-MM-DD') : '',
                            insurancePolicyRenewalDate: data.insurancePolicyRenewalDate ? moment(data.insurancePolicyRenewalDate).format('YYYY-MM-DD') : ''
                        });

                        // Update Vehicle Details
                        setVehicleDetails({
                            vehicleModel: data.vehicleModel,
                            insuranceValue: data.insuranceValue,
                            vehicleReg: data.registrationNumber,
                            loanVehicleID: data.loanVehicleID,
                            dateCreated: data.dateCreated ? moment(data.dateCreated).format('YYYY-MM-DD') : '',
                            loanVehicleUsageID: data.loanVehicleUsageID
                        });

                        // Update Booking Details
                        setBookingDetails({
                            dateOut: data.dateOut ? moment(data.dateOut).format('YYYY-MM-DDTHH:mm') : '',
                            dateIn: data.dateIn ? moment(data.dateIn).format('YYYY-MM-DDTHH:mm') : '',
                            estimatedDateDueIn: data.estimatedDateDueIn ? moment(data.estimatedDateDueIn).format('YYYY-MM-DDTHH:mm') : '',
                            mileageOut: data.mileageOut,
                            mileageIn: data.mileageIn,
                            fuelOut: data.fuelOut,
                            fuelIn: data.fuelIn
                        });

                        // Update Handover Details
                        setHandOverDetails({
                            handedOverName: data.handedOverName,
                            handOverCustomerSignature: data.handOverCustomerSignature,
                            handoverCustomerSignatureOut: data.handoverCustomerSignatureOut,
                            handoverStaffSignatureOut: data.handoverStaffSignatureOut,
                            handoverCustomerSignatureIn: data.handoverCustomerReturnSignature,
                            handoverStaffSignatureIn: data.handoverStaffReturnSignature
                        });

                        setLoanVehicleOutImages(res?.data?.loanVehicleOutImages ? res?.data?.loanVehicleOutImages?.map((image) => image.outVehicleImage) : []);
                        setLoanVehicleInImages(res?.data?.loanVehicleInImages ? res?.data?.loanVehicleInImages.map((image) => image.inVehicleImage) : []);
                        setState((prev) => ({
                            ...prev,
                            handOverCustomerSignature: data.handOverCustomerSignature,
                            handoverCustomerSignatureOut: data.handoverCustomerSignatureOut,
                            handoverStaffSignatureOut: data.handoverStaffSignatureOut,
                            handoverCustomerSignatureIn: data.handoverCustomerReturnSignature,
                            handoverStaffSignatureIn: data.handoverStaffReturnSignature,
                            loading: false,
                            isVehicleInState: data.handoverCustomerSignatureOut && data.handoverStaffSignatureOut ? true : false,
                            isVehicleReceived: data.handoverCustomerReturnSignature && data.handoverStaffReturnSignature ? true : false
                        }));
                    } else {
                        setState((prev) => ({
                            ...prev,
                            error: 'Failed to fetch loan vehicle data',
                            loading: false,
                            isVehicleInState: false,
                            isVehicleReceived: false
                        }));
                    }
                }
            } catch (error) {
                console.error('Error fetching loan vehicle data:', error);
                setState((prev) => ({
                    ...prev,
                    loading: false
                }));
            }
        };

        setState((prev) => ({ ...prev, loading: true }));
        fetchData();
    }, [HeaderRecordID]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleInImageUpload = async (event) => {
        const files = Array.from(event.target.files);

        // Convert files to base64
        const newImages = await Promise.all(
            files.map(async (file) => {
                const base64 = await convertToBase64(file);
                return {
                    file,
                    preview: URL.createObjectURL(file),
                    base64: base64
                };
            })
        );

        setLoanVehicleInImages((prev) => [...prev, ...newImages]);
    };

    const handleInRemoveImage = (indexToRemove) => {
        setLoanVehicleInImages((prev) => prev.filter((_, index) => index !== indexToRemove));
    };

    const handleOutImageUpload = async (event) => {
        const files = Array.from(event.target.files);

        const newImages = await Promise.all(
            files.map(async (file) => {
                const base64 = await convertToBase64(file);
                return {
                    file,
                    preview: URL.createObjectURL(file),
                    base64: base64
                };
            })
        );

        setLoanVehicleOutImages((prev) => [...prev, ...newImages]);
    };

    const handleOutRemoveImage = (indexToRemove) => {
        setLoanVehicleOutImages((prev) => prev.filter((_, index) => index !== indexToRemove));
    };

    const validateMileage = (name, value) => {
        if (name === 'mileageIn' && value !== '') {
            const mileageOut = Number(bookingDetails.mileageOut);
            const mileageIn = Number(value);

            if (mileageIn < mileageOut) {
                setErrors((prev) => ({
                    ...prev,
                    mileageIn: 'Mileage In must be greater than Mileage Out'
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    mileageIn: null
                }));
            }
        }
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;
        if (!vehicleDetails.vehicleReg) {
            errors.vehicleReg = 'Vehicle Reg is required';
            isValid = false;
        }
        if (!vehicleDetails.vehicleModel) {
            errors.vehicleModel = 'Vehicle Model is required';
            isValid = false;
        }
        if (!vehicleDetails.dateCreated) {
            errors.dateCreated = 'Date is required';
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const validateVehicleIn = () => {
        let errors = {};
        let isValid = true;
        if (!vehicleDetails.vehicleReg) {
            errors.vehicleReg = 'Vehicle Reg is required';
            isValid = false;
        }
        if (!vehicleDetails.vehicleModel) {
            errors.vehicleModel = 'Vehicle Model is required';
            isValid = false;
        }
        if (!vehicleDetails.dateCreated) {
            errors.dateCreated = 'Date is required';
            isValid = false;
        }
        if (!companyDetails.companyContactName) {
            errors.companyContactName = 'Company Name is required';
            isValid = false;
        }
        if (!companyDetails.companyContactCompany) {
            errors.companyContactCompany = ' Company is required';
            isValid = false;
        }
        if (!companyDetails.companyContactAddress) {
            errors.companyContactAddress = 'Address is required';
            isValid = false;
        }
        if (!companyDetails.companyDriverName) {
            errors.companyDriverName = 'Driver Name is required';
            isValid = false;
        }
        if (!companyDetails.companyRegistrationNumber) {
            errors.companyRegistrationNumber = 'Registration Number is required';
            isValid = false;
        }
        if (!companyDetails.companyWIPNumber) {
            errors.companyWIPNumber = 'WIP Number is required';
            isValid = false;
        }

        if (!handOverDetails.handOverCustomerSignature) {
            errors.handOverCustomerSignature = 'Customer Signature is required';
            isValid = false;
        }

        if (!bookingDetails.dateIn) {
            errors.dateIn = 'Date In is required';
            isValid = false;
        }

        if (!bookingDetails.mileageIn) {
            errors.mileageIn = 'Mileage In is required';
            isValid = false;
        }
        if (!bookingDetails.fuelIn) {
            errors.fuelIn = 'Fuel In is required';
            isValid = false;
        }

        if (!handOverDetails.handOverCustomerSignature) {
            errors.handOverCustomerSignature = 'Customer Signature is required';
            isValid = false;
        }

        if (!handOverDetails.handoverCustomerSignatureIn) {
            errors.handoverCustomerSignatureIn = 'Customer Signature is required';
            isValid = false;
        }
        if (!handOverDetails.handoverStaffSignatureIn) {
            errors.handoverStaffSignatureIn = 'Staff Signature is required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const validateVehicleOut = () => {
        let errors = {};

        let isValid = true;
        if (!vehicleDetails.vehicleReg) {
            errors.vehicleReg = 'Vehicle Reg is required';
            isValid = false;
        }
        if (!vehicleDetails.vehicleModel) {
            errors.vehicleModel = 'Vehicle Model is required';
            isValid = false;
        }
        if (!vehicleDetails.dateCreated) {
            errors.dateCreated = 'Date is required';
            isValid = false;
        }
        if (!companyDetails.companyContactName) {
            errors.companyContactName = 'Company Name is required';
            isValid = false;
        }
        if (!companyDetails.companyContactCompany) {
            errors.companyContactCompany = ' Company is required';
            isValid = false;
        }
        if (!companyDetails.companyContactAddress) {
            errors.companyContactAddress = 'Address is required';
            isValid = false;
        }
        if (!companyDetails.companyDriverName) {
            errors.companyDriverName = 'Driver Name is required';
            isValid = false;
        }
        if (!companyDetails.companyRegistrationNumber) {
            errors.companyRegistrationNumber = 'Registration Number is required';
            isValid = false;
        }
        if (!companyDetails.companyWIPNumber) {
            errors.companyWIPNumber = 'WIP Number is required';
            isValid = false;
        }

        if (!handOverDetails.handOverCustomerSignature) {
            errors.handOverCustomerSignature = 'Customer Signature is required';
            isValid = false;
        }

        if (!bookingDetails.dateOut) {
            errors.dateOut = 'Date Out is required';
            isValid = false;
        }
        if (!bookingDetails.mileageOut) {
            errors.mileageOut = 'Mileage Out is required';
            isValid = false;
        }
        if (!bookingDetails.fuelOut) {
            errors.fuelOut = 'Fuel Out is required';
            isValid = false;
        }

        // if (!bookingDetails.estimatedDateDueIn) {
        //     errors.estimatedDateDueIn = 'Estimated Date Due In is required';
        //     isValid = false;
        // }
        if (!handOverDetails.handOverCustomerSignature) {
            errors.handOverCustomerSignature = 'Customer Signature is required';
            isValid = false;
        }

        if (!handOverDetails.handoverCustomerSignatureOut) {
            errors.handoverCustomerSignatureOut = 'Customer Signature is required';
            isValid = false;
        }
        if (!handOverDetails.handoverStaffSignatureOut) {
            errors.handoverStaffSignatureOut = 'Staff Signature is required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSave = async () => {
        if (validateForm()) {
            handleSubmit();
        }
    };

    const handleSaveOut = async () => {
        if (validateVehicleOut()) {
            handleSubmit();
        }
    };

    const handleSaveIn = async () => {
        if (validateVehicleIn()) {
            handleSubmit();
        }
    };

    const parseErrors = (errorObj) => {
        const errorMessages = [];
        if (errorObj.errors) {
            Object.entries(errorObj.errors).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((errorMsg) => errorMessages.push(`${key}: ${errorMsg}`));
                } else {
                    errorMessages.push(`${key}: ${value}`);
                }
            });
        } else {
            errorMessages.push('An unknown error occurred.');
        }
        return errorMessages;
    };

    const handleSubmit = async () => {
        try {
            // if (validateForm()) {
            setLoading(true);
            const payload = {
                headerRecordID: HeaderRecordID,
                dateCreated: vehicleDetails.dateCreated ? moment(vehicleDetails.dateCreated).format('YYYY-MM-DD') : '',
                loanVehicleID: vehicleDetails.loanVehicleID,
                loanVehicleUsageID: vehicleDetails.loanVehicleUsageID,

                registrationNumber: vehicleDetails.vehicleReg,
                insuranceValue: vehicleDetails.insuranceValue,
                dateOut: bookingDetails.dateOut ? moment(bookingDetails.dateOut).format('YYYY-MM-DDTHH:mm') : '',
                companyContactName: companyDetails.companyContactName,
                companyContactEmail: companyDetails.companyContactEmail,
                companyContactCompany: companyDetails.companyContactCompany,
                companyContactNumber: companyDetails.companyContactNumber,
                companyContactAddress: companyDetails.companyContactAddress,
                companyContactPostCode: companyDetails.companyContactPostCode,
                companyContactReasonForUse: companyDetails.companyContactReasonForUse,
                companyDriverName: companyDetails.companyDriverName,
                companyRegistrationNumber: companyDetails.companyRegistrationNumber,
                companyWIPNumber: companyDetails.companyWIPNumber,
                insuranceCompanyName: insuranceDetails.insuranceCompanyName,
                insuranceCompanyAddress: insuranceDetails.insuranceCompanyAddress,
                insuranceCompanyPolicyNumber: insuranceDetails.insuranceCompanyPolicyNumber,
                insurancePolicyStartDate: insuranceDetails.insurancePolicyStartDate
                    ? moment(insuranceDetails.insurancePolicyStartDate).format('YYYY-MM-DD')
                    : '',
                insurancePolicyRenewalDate: insuranceDetails.insurancePolicyRenewalDate
                    ? moment(insuranceDetails.insurancePolicyRenewalDate).format('YYYY-MM-DD')
                    : '',
                vehicleModel: vehicleDetails.vehicleModel,
                dateIn: bookingDetails.dateIn ? moment(bookingDetails.dateIn).format('YYYY-MM-DDTHH:mm') : '',
                mileageOut: bookingDetails.mileageOut,
                mileageIn: bookingDetails.mileageIn,
                fuelOut: bookingDetails.fuelOut,
                fuelIn: bookingDetails.fuelIn,
                estimatedDateDueIn: bookingDetails.estimatedDateDueIn,
                handOverCustomerSignature: handOverDetails.handOverCustomerSignature,
                handedOverName: handOverDetails.handedOverName,
                handoverCustomerSignatureOut: handOverDetails.handoverCustomerSignatureOut,
                handoverStaffSignatureOut: handOverDetails.handoverStaffSignatureOut,
                handoverCustomerReturnSignature: handOverDetails.handoverCustomerSignatureIn,
                handoverStaffReturnSignature: handOverDetails.handoverStaffSignatureIn,
                loanVehicleInImages: loanVehicleInImages.filter((image) => image.base64).map((image) => image.base64),
                loanVehicleOutImages: loanVehicleOutImages.filter((image) => image.base64).map((image) => image.base64)
            };

            const response = await postLoanVehicleUsage(payload);
            if (response.success) {
                showToast(response.message);
                handleCancel();
                setLoading(false);
            } else {
                const errorMessages = parseErrors(response);
                errorMessages.map((msg, index) => showToast(msg));

                setLoading(false);
            }
            // }
            // return response;
        } catch (error) {
            showToast('Error submitting loan vehicle form');
            setLoading(false);
            throw error;
        }
    };

    return (
        <LoanVehicleContext.Provider
            value={{
                companyDetails,
                handleCompanyContactDetails,
                insuranceDetails,
                handleInsuranceDetails,
                bookingDetails,
                handleBookingDetails,
                handOverDetails,
                fieldChange,
                clearSig,
                state,
                handleHandOverDetails,
                vehicleDetails,
                handleVehicleDetails,
                handleInImageUpload,
                handleInRemoveImage,
                loanVehicleInImages,
                loanVehicleOutImages,
                handleOutImageUpload,
                handleOutRemoveImage,
                handleSubmit,
                searchLoanVehicle,
                getRegNubmer,
                handleCancel,
                loading,
                errors,
                handleSave,
                handleSaveOut,
                handleSaveIn
            }}
        >
            {props.children}
        </LoanVehicleContext.Provider>
    );
};
