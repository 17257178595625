import { CircularProgress, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { CompanyDetails } from './companyDetails';
import InsuranceDetails from './insuranceDetails';
import LoanVehicleDetails from './loanVehicleDetails';
import BookingDetails from './bookingDetails';
import Note from './note';
import { LoanVehicleContext, LoanVehicleProvider } from './loanVehicle-provider';
import { SecondaryButton } from '../../../Core/FormInput';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { HandoverOUtSignature, HandoverReturnSignature } from './handoverOUtSignature';

const LoanVehicleBooking = (props) => {
    const { handleCancel, state, loading, handleSave, handleSaveOut, handleSaveIn } = useContext(LoanVehicleContext);

    return (
        <div style={{ padding: 10, background: 'white' }}>
            {state.loading ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <LoanVehicleDetails />
                    </Grid>
                    <Grid item md={12}>
                        <CompanyDetails />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <InsuranceDetails />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <Note />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <HandoverOUtSignature />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <HandoverReturnSignature />
                    </Grid>

                    <Grid item xs={12} sm={state.isVehicleReceived ? 4 : 6}>
                        <SecondaryButton fullWidth onClick={handleSave} disabled={loading}>
                            {loading ? <CircularProgress size={20} /> : 'Save'}
                        </SecondaryButton>
                    </Grid>
                    {state.isVehicleReceived ? (
                        <Grid item xs={12} sm={4}>
                            <SecondaryButton fullWidth onClick={state.isVehicleInState ? handleSaveIn : handleSaveOut} disabled={loading}>
                                {loading ? <CircularProgress size={20} /> : state.isVehicleInState ? 'Vehicle Returned' : 'Handover Vehicle'}
                            </SecondaryButton>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={state.isVehicleReceived ? 4 : 6}>
                        <SecondaryButton fullWidth onClick={handleCancel}>
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
let ProviderScreen = (props) => {
    return (
        <LoanVehicleProvider {...props}>
            <LoanVehicleBooking {...props} />
        </LoanVehicleProvider>
    );
};
export default ProviderScreen;
