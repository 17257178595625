import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getLoanVehicleHistory } from '../../../Core/Service/loan-vehicle-service';
import { Typography, CircularProgress } from '@material-ui/core';
import DataGridComp from '../../../Core/Grid';
import { GridOverlay } from '@material-ui/data-grid';
import formatters from '../../../Core/Grid/inputFormatter';

const LoanVehicleHistory = (id) => {
    
    const [state, setState] = useState({
        rows: [],
    });
    const [loading, setLoading] = useState(false); 
    const history = useHistory();

    useEffect(() => {
        if (id) {
            pullHistoryAndUpdateState();
        }
    }, [id]);

    const pullHistoryAndUpdateState = async () => {
        if (!id) return;

        setLoading(true);

        let res = await getLoanVehicleHistory(id);

        if (res.success) {
            let rows = res?.data?.detail?.table || [];
            rows.forEach((item, i) => {
                item.id = i + 1;
            });

            setState((st) => ({
                ...st,
                rows: rows,
            }));
        }

        setLoading(false);
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Invoices Emailed</div>
            </GridOverlay>
        );
    }

    const handleRedirectToJobDetails = (row) => {
        if (row.headerRecordID && row.workflowID) {
            history.push(`/Job/${row.headerRecordID}/${row.workflowID}`);
        } else {
            console.error('Missing headerRecordID or workFlowID');
        }
    };

    const column_new = [
        {
            field: 'dateOut',
            headerName: 'Handed Over',
            // flex: 1.4,
            width: 160,
            renderCell: (params) =>
                params.value ? formatters.DateTimeFormatter(params.value) : '-',
        },
        {
            field: 'dateIn',
            headerName: 'Returned',
            // flex: 1.4,
            width: 150,
            renderCell: (params) =>
                params.value ? formatters.DateTimeFormatter(params.value) : '-',
        },
        {
            field: 'daysOnLoan',
            headerName: 'Days On Loan',
            // flex: 1.4,
            width: 160,
            renderCell: (params) => {
                const dateOut = params.row.dateOut ? new Date(params.row.dateOut) : null;
                const dateIn = params.row.dateIn ? new Date(params.row.dateIn) : new Date();

                if (!dateOut) return '-';

                const timeDiff = dateIn - dateOut;
                const daysOnLoan = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                return daysOnLoan >= 0 ? daysOnLoan : '-';
            },
        },
        {
            field: 'wipNumber',
            headerName: 'WIP No.',
            // flex: 1.3,
            width: 120,
            renderCell: (params) => (
                <span
                    style={{ textDecoration: 'underline', cursor: 'pointer',fontWeight: 'bold' }}
                    onClick={() => {
                        handleRedirectToJobDetails(params.row);
                    }}
                >
                    {params.value}
                </span>
            ),
        },
        {
            field: 'registration',
            headerName: 'Reg No.',
            // flex: 1.3,
            width: 120,
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            // flex: 1.9,
            width: 180,
        },
    ];

    return (
        <div className="report-screen-container">
            {loading ? ( 
                <div style={{ textAlign: 'center', margin: '20px'}}>
                    <CircularProgress /> 
                    <Typography style={{ fontWeight: 'bold' }}>
                        Loading Vehicle History...
                    </Typography>
                </div>
            ) : state?.rows?.length === 0 ? (
                <Typography
                    style={{
                        textAlign: 'center',
                        color: 'red',
                        fontWeight: 'bold',
                    }}
                >
                    No Vehicle History
                </Typography>
            ) : (
                <DataGridComp
                    rows={state.rows}
                    columns={column_new}
                    // width={600}
                    enableSearch={false}
                    offset={244}
                    CustomNoRowsOverlay={CustomNoRowsOverlay}
                />
            )}
        </div>
    );
};

export default LoanVehicleHistory;
